export enum AppMutation {
    SET_ORIENTATION = 'SET_ORIENTATION',
    SET_DEVICE = 'SET_DEVICE',
    INIT_USER_SESSION = 'INIT_USER_SESSION',
    HIDE_LOADING = 'HIDE_LOADING',
    SHOW_LOADING = 'SHOW_LOADING',
    SET_COIN = 'SET_COIN',
    CLEAR_ALL = 'CLEAR_ALL',
    SET_LOBY_NOTICE = 'SET_LOBY_NOTICE',
    SET_DESK_NOTICE = 'SET_DESK_NOTICE',
    ADD_RESULT_TO_CACHE = 'ADD_RESULT_TO_CACHE',
    SET_SOUND = 'SET_SOUND',
    SET_USER_INTERACTION = 'SET_USER_INTERACTION',
    SET_LANG = 'SET_LANG',
    SET_DESK_OPTIONS = 'SET_DESK_OPTIONS',
    SET_CHIPS = 'SET_CHIPS',
    SET_CURRENT_CHIPS = 'SET_CURRENT_CHIPS',
    SET_CUSTOM_CHIP = 'SET_CUSTOM_CHIP',
    SET_LIMIT = 'SET_LIMIT',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_TOKEN = 'SET_TOKEN',
    SET_MEMBER_ID = 'SET_MEMBER_ID',
    SET_USER = 'SET_USER',
    SET_IS_FREE = 'SET_IS_FREE',
    SET_COMMAND_TYPE = 'SET_COMMAND_TYPE',
    SET_VIDEO = 'SET_VIDEO',
    SET_IS_GUNNER = 'SET_IS_GUNNER',
    SET_USER_INFO = 'SET_USER_INFO',
    SET_DESK = 'SET_DESK',
    SET_VISITOR_DESK = 'SET_VISITOR_DESK',
    SET_CLIENT_TIME_OUT = 'SET_CLIENT_TIME_OUT',
    SET_USER_GUNNER_BIND = 'SET_USER_GUNNER_BIND',
    APP_SET_LINE = 'APP_SET_LINE',
    APP_SET_LINE_STR = 'APP_SET_LINE_STR',
    SET_MULTI_TABLE = 'SET_MULTI_TABLE',
    SET_IPV_NOTICE = 'SET_IPV_NOTICE'
}

export enum AppActions {
    FETCH_DATA = 'FETCH_DATA',
    PING_SERVER = 'pingServer'
}
